import useSiteOptions from 'providers/useOptions';
import useModals from 'providers/useModals';

import Form from 'components/form';
import Modal from 'components/modal';

import s from 'styles/modals/conversion';

import socialLinkIcons from 'util/social-link-icons';
import Link from 'next/link';

export default function ConversionModal () {
	const options = useSiteOptions();
	const modals = useModals();

	const contactInfo = options.contact_information;
	const socialMedia = options.social_media;
	const data = options.conversion_modal;

	return (
		<Modal id="conversion" isGlobal={true}>
			<s.ModalInner fill={1}>
				<s.LeftContent>
					<s.Headline dangerouslySetInnerHTML={{__html: data?.content.headline}}/>

					<Form config={data?.content.form.config} submitText={data?.content.text_before_submit || false}/>
				</s.LeftContent>

				<s.RightContent>
					<s.SmallText dangerouslySetInnerHTML={{__html: data?.info.small_text}}></s.SmallText>

					<s.ContactBlocks>
						{data?.info.blocks.map((block, key) => {
							let textHTML = null;

							if (block.acf_fc_layout === 'address') {
								textHTML = contactInfo.address;
							} else if (block.acf_fc_layout === 'custom') {
								textHTML = block.text;
							}

							return (
								<s.ContactBlock key={key}>
									<s.ContactBlockTitle>{block.title}</s.ContactBlockTitle>

									{textHTML ? (
										<s.ContactBlockText dangerouslySetInnerHTML={{__html: textHTML}}/>
									) : (
										<s.ContactBlockText>
											{block.acf_fc_layout === 'phone-number' && (
												<a href={`tel:${contactInfo.phone_number.replace(/\D/g, '')}`}>
													{contactInfo.phone_number}
												</a>
											)}

											{block.acf_fc_layout === 'email-address' && (
												<a href={`mailto:${contactInfo.email_address}`}>
													{contactInfo.email_address}
												</a>
											)}
										</s.ContactBlockText>
									)}
								</s.ContactBlock>
							);
						})}
					</s.ContactBlocks>

					<s.SocialLinks>
						{socialMedia.map((link, key) => {
							const Icon = socialLinkIcons[link.icon.value];

							if (!Icon) return <></>;

							return (
								<Link href={link.url || '#'} target="_blank" title={link.icon.label} key={key} passHref>
									<a>
										<Icon/>
									</a>
								</Link>
							);
						})}
					</s.SocialLinks>
				</s.RightContent>
			</s.ModalInner>
		</Modal>
	);
}