import Link from 'next/link';
import s from 'styles/components/menu';
import {useRef} from 'react';

export default function Menu ({items, onMenuHover}) {
	const megaMenuTimeoutRef = useRef();

	const openMegaMenu = (index) => {
		clearTimeout(megaMenuTimeoutRef.current);
		megaMenuTimeoutRef.current = setTimeout(() => onMenuHover(index), 500);
	};

	const resetMegaMenu = () => {
		clearTimeout(megaMenuTimeoutRef.current);
		onMenuHover(null);
	};

	const MenuLink = ({enable_sub_menu, title, url, target}) => (
		<Link key={title} href={url || '#'} target={target} passHref>
			<s.Link className="nav-link">
                <span>
                    {title}
	                {enable_sub_menu && <s.LinkIcon className="icon"/>}
                </span>
			</s.Link>
		</Link>);

	return items.map((item, index) => {
		const {title, url, target} = item.link;
		const link = {
			enable_sub_menu: item.enable_sub_menu,
			title, url, target,
		};

		return <s.LinkWrapper key={index}
		                      onMouseEnter={() => item.enable_sub_menu && openMegaMenu(index)}
		                      onMouseLeave={() => resetMegaMenu()}
		>
			{MenuLink(link)}

			{item.sub_menu_type === 'dropdown' ? <s.DropdownWrapper className="dropdown">
				{item.items.map(subitem => MenuLink(subitem))}
			</s.DropdownWrapper> : null}
		</s.LinkWrapper>;
	});
}