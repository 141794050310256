import styled from "styled-components";
import fluid from "util/fluid";
import ChevronDown from 'public/icons/chevron-down.svg';
import rem from "util/rem";
import { plusIcon } from "styles/mixins";
import Pipe from 'public/images/pipes.svg';
import Collapsible from 'components/collapsible';

export default Object.freeze({
    TopDecoration: styled(Pipe)`
        position: absolute;
        top: 0;
        left: 0;
        width: max-content;
        height: fit-content;
        opacity: 0.15;
        pointer-events: none;
    `,

    Content: styled.div`
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100%;
        background-color: ${p => p.theme.colors.dark};
        padding: ${fluid(10, 50, 'phone-xsmall', 'tablet')};
        padding-bottom: ${fluid(55, 75)};
        color: ${p => p.theme.colors.light};
        transform: translateX(50%);
        visibility: hidden;
        overflow-y: scroll;
        overflow-x: hidden;
        opacity: 0;
        z-index: 90;
        transition: all ${p => p.theme.transitions.normal} ease-out;

        ${p => p.active && `
        transform: translateX(0);
        opacity: 1;
        visibility: visible;
        `}
    `,

    Select: styled.select`
        font-size: ${p => p.theme.fontSizes.normal};
        background: transparent;
        border: none;
        color: ${p => p.theme.colors.light};
        text-transform: uppercase;
        -webkit-appearance: none;
        padding-right: ${fluid(25, 50, 'phone-xsmall', 'tablet')};
        padding-left: ${fluid(5, 10, 'phone-xsmall', 'tablet')};
        width: 100%;

        &:focus,
        &:active {
            outline: none;
        }

        option {
            color: ${p => p.theme.colors.dark};
        }
    `,

    Label: styled.span`
        font-size: ${p => p.theme.fontSizes.normal};
        display: inline-block;
        flex-shrink: 0;
        padding-left: ${fluid(25, 50, 'phone-xsmall', 'tablet')};
        text-transform: uppercase;
    `,

    SelectWrapper: styled.div`
        position: relative;
        display: flex;
        align-items: center;
        background-color: ${p => p.theme.colors.gray('900')};
        margin-bottom: ${rem(25)};
        height: ${fluid(43, 80, 'phone-xsmall', 'tablet')}
    `,

    ChevronDown: styled(ChevronDown)`
        position: absolute;
        right: ${fluid(25, 50, 'phone-xsmall', 'tablet')};
        top: 50%;
        transform: translateY(-50%);
        width: ${fluid(12, 20, 'phone-xsmall', 'tablet')};
        pointer-events: none;
    `,

    Actions: styled.div`
        padding-left: ${rem(25)};
    `,

    Nav: styled.nav`
        margin-top: ${rem(40)};
        
        .collapsible.active {
            em:after {
                transform: rotate(90deg) translateX(-50%);
                opacity: 0;
            }
        }
    `,

    Collapsible: styled(Collapsible)`
        margin-bottom: ${fluid(25, 40, 'phone-xsmall', 'tablet')};
    `,

    CollapsibleHeader: styled.header`
        display: grid;
        align-items: center;
        grid-template-columns: max-content 1fr;
        gap: ${rem(10)};
    `,

    CollapsibleTitle: styled.p`
        display: block;
        color: ${p => p.theme.colors.light};
        font-size: ${rem(20)};
        font-weight: 900;
        text-transform: uppercase; 
    `,

    Link: styled.a`
        display: block;
        color: ${p => p.theme.colors.light};
        font-size: ${rem(20)};
        font-weight: 900;
        text-transform: uppercase; 
        margin-bottom: ${fluid(25, 40, 'phone-xsmall', 'tablet')};
    `,

    Spacer: styled.em`
        display: block;
        width: ${rem(12)};
    `,

    Icon: styled.em`
        ${plusIcon(rem(12))};

        color: ${p => p.theme.colors.secondary};
        opacity: 0;

        ${p => p.active && `
            opacity: 1;
        `}
    `,

    SubLinks: styled.div`
        display: flex;
        flex-direction: column;
        margin-top: ${rem(22)};

        &:after,
        &:before {
            content: '';
            display: block;
            height: ${rem(12)};
        }

        &:before {
            border-top: ${rem(1)} solid ${p => p.theme.colors.gray('900')};
            border-left: ${rem(1)} solid ${p => p.theme.colors.gray('900')};
        }
        
        &:after {
            border-bottom: ${rem(1)} solid ${p => p.theme.colors.gray('900')};
            border-left: ${rem(1)} solid ${p => p.theme.colors.gray('900')};
        }
    `,

    SubLink: styled.a`  
        color: ${p => p.theme.colors.gray('400')};
        font-size: ${p => p.theme.fontSizes.normal};
        padding-left: ${rem(25)};
        padding-right: ${rem(25)};

        &:not(:last-child) {
            margin-bottom: ${rem(15)};
        }
    `
});