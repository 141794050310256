import Sections from 'components/sections';
import QualifyButton from './qualify-button';
import ConversionModal from 'modals/conversion';
import {AnimatePresence} from 'framer-motion';
import Layout from './layout';
import Head from 'next/head';

export default function Page({pageData, skipSections = false, children}) {
    // Prepare the page sections
    const pageSections = !skipSections && pageData?.fields.sections && (
        <Sections
            list={pageData.fields.sections}
            pageData={pageData}
        />
    );

    // Prepare the floating Quality CTA

    // The page content
    const PageContent = () => {
        switch (typeof children) {
            case 'function':
                return children(pageSections);

            case 'undefined':
                return pageSections;

            default:
                return children;
        }
    };

    // Render
    return (
        <main>
            <AnimatePresence mode="wait">
                <Layout>
                    <PageContent/>
                </Layout>
            </AnimatePresence>

            <ConversionModal/>
            <QualifyButton showButton={pageData?.fields.qualify_now_button}/>
        </main>
    );
}
