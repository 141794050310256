import Wrapper from "components/wrapper";
import styled from "styled-components";
import fluid from "util/fluid";
import { mediaDown, mediaUp } from "util/media-query";
import rem from "util/rem";

export default {
  Logo: styled.figure`
    width: ${fluid(70, 170)};
    cursor: pointer;

    ${mediaDown("desktop-small")} {
      filter: brightness(0) invert(1);
    }

    ${(p) =>
      p.color === "light" &&
      `
		filter: brightness(0) invert(1);
		`}
  `,

  HeaderTop: styled.div`
    display: none;

    ${mediaUp("desktop-small")} {
      display: block;
      position: absolute;
      left: 0;
      width: 100%;
      z-index: 10;
    }
  `,

  Header: styled.header`
    position: fixed;
    left: 0;
    width: 100%;
    z-index: 100;
    // opacity: ${(p) => (p.visible ? 1 : 0)};
    transition: opacity 500ms;

    ${(p) =>
      !p.defaultStyling &&
      `
		background: ${p.theme.colors.gray("900")};
		bottom: 0;
		`}
    ${(p) =>
      p.defaultStyling &&
      `
		background: ${p.color == "light" ? "rgba(255, 255, 255, 0)" : null};
		background: ${p.color == "dark" ? p.theme.colors.dark : null};
		box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
		backdrop-filter: blur(1.5px);
		`}
        ${mediaUp("desktop-small")} {
      position: ${(p) => p.position};
      background: ${(p) => p.theme.colors.light};

      ${(p) =>
        p.position != "relative" &&
        ` // Any other positioning different of relative, add the below style
			top: ${
        p.position == "absolute" ? rem(p.offset) : 0
      }; // Offset Added only when the header must be below the first section
			bottom: initial;
			left: 0;
			width: 100%;
			`}
    }
  `,

  Nav: styled.div`
    display: none;

    ${mediaUp("desktop-small")} {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: ${fluid(20, 40)};
    }
  `,

  Wrapper: styled(Wrapper)`
    display: grid;
    grid-template-columns: ${fluid(70, 170)} 1fr max-content;
    justify-content: space-between;
    align-items: center;
    column-gap: ${rem(40)};
    padding-top: ${fluid(15, 20)};
    padding-bottom: ${fluid(15, 20)};
  `,

  Actions: styled.div`
    display: visible;

    ${mediaUp("desktop-small")} {
      display: grid;
      grid-auto-flow: columns;
      column-gap: ${rem(20)};
    }
  `,

  MobileMenu: styled.div`
    display: block;

    ${mediaUp("desktop-small")} {
      display: none;
    }
  `,

  MenuLabel: styled.label`
    position: absolute;
    top: 60%;
    transform: translateY(-50%);
    right: ${rem(55)};
    color: ${(p) => p.theme.colors.light};
    font-size: ${rem(10)};
    font-weight: 900;
    text-transform: uppercase;
  `,

  MenuTrigger: styled.button`
    display: flex;
    align-items: center;
    z-index: 1;
    justify-content: center;
    flex-direction: column;
    row-gap: ${rem(5)};
    position: absolute;

    ${(p) =>
      p.defaultStyling &&
      `
		right: ${rem(20)};

		span {
			background-color: ${p.theme.colors.secondary};
		}
		`}
    ${(p) =>
      !p.defaultStyling &&
      `
		bottom: ${rem(-20)};
		right: ${rem(-20)};
		width: ${rem(100)};
		height: ${rem(100)};
		border-radius: 100%;
		background-color: ${p.theme.colors.secondary};

		span {
			background-color: ${p.theme.colors.light};
		}
		`}
        span {
      display: block;
      width: ${rem(26)};
      height: ${rem(2)};
      transition: transform ${(p) => p.theme.transitions.fast} linear;

      &:first-child {
        transform-origin: 40% 0;
      }

      &:last-child {
        transform-origin: 20% 0;
      }
    }

    ${(p) =>
      p.active &&
      `
		span {
			&:first-child {
				transform: rotate(45deg);
			}
			
			&:last-child {
				transform: rotate(-45deg);
			}
		}
		`}
  `,
};
