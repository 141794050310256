import styled, {css} from 'styled-components';
import rem from 'util/rem';
import {mediaUp} from 'util/media-query';
import fluid from 'util/fluid';
import {rgb, rgba} from 'polished';

const FormWrapper = styled.div`
    form {
        display: flex;
        flex-flow: column;
        gap: ${fluid(24, 50)};
        width: 100%;
        font-family: ${p => p.theme.fonts.text};
    }
`;

const FeedbackMessage = styled.div`
    width: 100%;
    text-align: center;
    color: ${p => p.type === 'error' && 'red' ||
        p.type === 'success' && 'green' ||
        'black'};
`;

const FormFields = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: ${fluid(24, 38)};

    ${mediaUp('tablet')} {
        grid-template-columns: repeat(12, 1fr);
    }
`;

const FormActions = styled.div`
    display: flex;
    flex-flow: column;
    gap: 24px;

    ${mediaUp('tablet')} {
        flex-flow: row;
        align-items: center;
        justify-content: center;
        gap: 100px;
        padding: 0 ${fluid(20, 100, 'tablet')}
    }
`;

const BlankFormCell = styled.div`
    ${mediaUp('tablet')} {
        grid-column: span ${p => p.span || 12};
    }
`;

const Field = styled.div`
    position: relative;
    padding: 18px 16px 14px;
    border: 1px solid ${p => p.invalid ? 'red' : 'white'};
    opacity: ${p => p.disabled ? 0.6 : 1};
    pointer-events: ${p => p.disabled ? 'none' : 'unset'};

    ${mediaUp('tablet')} {
        grid-column: span ${p => p.span || 12};
    }
`;

const FieldLabel = styled.label`
    position: absolute;
    top: 0;
    left: 14px;
    z-index: 2;
    display: block;
    padding: 0 8px;
    transform: translateY(-50%);
    font-family: ${p => p.theme.fonts.text};
    font-size: ${fluid(12, 14)};
    font-style: italic;
    color: ${p => p.theme.colors.light};
    font-weight: 400;
    background: ${p => p.theme.colors.primary};

    ${p => p.required && `
		&::after {
			content: '*';
			margin-left: 4px;
			color: red;
		}
	`};
`;

const StyledInput = css`
    display: block;
    width: 100%;
    padding: 0 7px;
    font-family: ${p => p.theme.fonts.text};
    font-size: ${fluid(16, 18)};
    border: none;
    outline: none;
    transition: background-color 300ms;
    background: ${p => p.theme.colors.primary};
    color: ${p => p.theme.colors.light};

    &:focus {
        background: ${p => p.theme.colors.light};
        color: ${p => p.theme.colors.primary};
    }

    &::valid {
        background: ${p => p.theme.colors.light};
    }

    &::selection {
        color: ${p => p.theme.colors.light};
        background: ${p => p.theme.colors.primary};
    }

    &::placeholder {
        color: ${p => p.theme.colors.gray(300)};
        opacity: 1;
    }
`;

const FormInput = styled.input`
    ${StyledInput};

    height: ${fluid(22, 28)};
`;

const FormDropdown = styled.select`
    ${StyledInput};

    position: relative;
    top: -1px;
    height: ${fluid(16, 22)};
    padding-left: 3px;
    background: none;

    &:invalid {
        color: ${p => p.theme.colors.gray(300)};
    }

    > option:not([disabled]) {
        color: black;
    }
`;

const FormTextArea = styled.textarea`
    ${StyledInput};
`;

const FormChoices = styled.div`
    display: flex;
    flex-flow: column;
    gap: 8px;
    margin-bottom: -2px;
`;

const FormChoiceLabel = styled.div`
    font-size: ${rem(18)};
    line-height: 1.4;
`;

const StyledChoice = css`
    display: flex;
    gap: 12px;

    input {
        display: none;
    }

    span {
        position: relative;
        top: 2px;
        display: block;
        width: 20px;
        height: 20px;
        border: 1px solid #DDD;

        &::before {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            opacity: 0;
            transition: opacity 300ms;
        }
    }

    input:checked + span::before {
        opacity: 1;
    }
`;

const FormCheckbox = styled.label`
    ${StyledChoice};

    span {
        border-radius: 4px;

        &::before {
            content: 'X';
            color: ${p => p.theme.colors.primary};
        }
    }
`;

const FormRadio = styled.label`
    ${StyledChoice};

    span {
        border-radius: 50%;

        &::before {
            content: '';
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: ${p => p.theme.colors.primary};
        }
    }
`;

const SubmitText = styled.div`
    max-width: 360px;
    font-size: ${rem(16)};
    line-height: 1.4;
`;

export default {FormWrapper, FeedbackMessage};

export {
	FormFields,
	FormActions,
	BlankFormCell,
	Field,
	FieldLabel,
	FormInput,
	FormDropdown,
	FormTextArea,
	FormChoices,
	FormChoiceLabel,
	FormCheckbox,
	FormRadio,
	SubmitText,
};