import sectionsManager from 'util/sections-manager';

export default function Sections ({list, pageData}) {
	if (!list.length) return;

	return list.map((fields, key) => {
		if (typeof fields['acf_fc_layout'] === 'undefined') return null;

		const Section = sectionsManager.get(fields['acf_fc_layout']);
		if (!Section) return null;

		return <Section key={key} fields={fields} pageData={pageData}/>;
	});
}
