import React, { useState } from 'react';
import AddressForm from './address-form';
import s from 'styles/components/qualify-button';

export default function QualifyButton({ text, onClick, showButton }) {
  const [selected, setSelected] = useState(false);
  const [cancelled, setCancelled] = useState(false);

    const handleClick = () => {
        setSelected(!selected);
        onClick && onClick(selected);
    }

    const cancelButton = () => {
        setCancelled(true);
    }

    if (showButton) {
        return (
            <s.ButtonWrapper className={`${selected ? 'active' : ''} ${cancelled ? 'cancel' : ''}`}>
                <s.Cancel>
                    <button className='cancel-button' type="button" onClick={cancelButton}>
                        <i></i>
                    </button>
                </s.Cancel>
                <s.Button onClick={handleClick} className={selected ? 'hide' : ''}>
                        Qualify now
                </s.Button>
                <s.Form className={selected ? 'active' : ''}>
                    <AddressForm 
                        buttonText='Get Report'
                        fieldText='Get a custom report for your property'
                        buttonStyles={'dark'}
                    />
                    <s.CloseButton className='close-btn'>
                        <button
                            type="button"
                            onClick={handleClick}
                        >
                            <i></i>
                        </button>
				    </s.CloseButton>
                </s.Form>
            </s.ButtonWrapper>
        );
    } else {
        return 
    }
}