import ArrowRight from 'public/icons/arrow-right.svg';
import styled from 'styled-components';
import fluid from 'util/fluid';
import rem from 'util/rem';
import { mediaUp } from 'util/media-query';
import Wrapper from 'components/wrapper';

export default Object.freeze({
    Content: styled.div`
        position: absolute;
        left: 0;
        top: 100%;
        width: 100%;
        opacity: 0;
        visibility: hidden;
        background: ${p => p.theme.colors.light};
        transition: all ${p => p.theme.transitions.normal} ease ${p => p.theme.transitions.fast};

        ${p => p.active && `
            opacity: 1;
            transition-delay: 0s;
            visibility: visible;
        `}
    `,
    
    Wrapper: styled(Wrapper)`
        ${mediaUp('tablet')} {
            display: grid;
            grid-template-columns: 1fr ${fluid(300, 800, 'tablet')};
        }
    `,

    NavWrapper: styled.div`
        ${mediaUp('tablet')} {
            position: relative;
            z-index: 1;
            background: ${p => p.theme.colors.light};
        }
    `,

    Nav: styled.nav`
        ${mediaUp('tablet')} {
            max-height: ${rem(400)};
            overflow-y: auto;
            padding-top: ${fluid(30, 80, 'tablet')};
            padding-left: ${fluid(60, 135, 'tablet')};
        }

        ${mediaUp('desktop-mlarge')} {
            max-height: ${fluid(500, 600, 'desktop-mlarge')};
        }
    `,

    Item: styled.div`
        ${mediaUp('tablet')} {
            padding-top: ${rem(25)};
            padding-bottom: ${rem(25)};
            border-bottom: ${rem(1)} solid ${p => p.theme.colors.gray('300')};

            &:last-child {
                border-bottom: none;
                padding-bottom: 0;
            }

            &:first-child {
                padding-top: 0;
            }

            ${p => p.active && `
                .link {
                    color: ${p.theme.colors.dark};
                }

                .icon {
                    transform: translateX(${rem(10)});
                    opacity: 1;
                }

                .description-wrapper {
                    height: fit-content;
                }
            `}
        }
    `,
    
    Link: styled.a`
        ${mediaUp('tablet')} {
            display: flex;
            font-size: ${fluid(14, 35, 'tablet')};
            font-weight: 900;
            text-transform: uppercase;
            color: ${p => p.theme.colors.gray('600')};
            max-width: ${rem(675)};
        }
    `,

    DescriptionWrapper: styled.div`
        ${mediaUp('tablet')} {
            height: 0;
            overflow: hidden;
        }
    `,

    Description: styled.p`
        ${mediaUp('tablet')} {
            font-size: ${p => p.theme.fontSizes.large};
            line-height: 1.4;
            padding-bottom: ${rem(20)};
            padding-top: ${fluid(5, 35, 'tablet')};
            max-width: ${rem(675)};
        }
    `,

	LinkIcon: styled(ArrowRight)`
		${mediaUp('tablet')} {
            margin-left: ${fluid(5, 15, 'tablet')};
            width: ${fluid(15, 30, 'tablet')};
            color: ${props => props.theme.colors.primary};
            transition: all ${p => p.theme.transitions.normal} ease-out;
            opacity: 0;
        }
	`,

    MediaWrapper: styled.div`
        ${mediaUp('tablet')} {
            position: relative;
            pointer-events: none;
        }
    `,

    Figure: styled.figure`
        ${mediaUp('tablet')} {
            pointer-events: none;
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            opacity: 0;
            transition: all ${p => p.theme.transitions.normal} ease;

            ${p => p.active && `
            opacity: 1;
            `}
        }
    `,

    Bottom: styled.footer`
        ${mediaUp('tablet')} {
            display: grid;
            align-items: center;
            grid-template-columns: 1fr ${fluid(200, 355, 'tablet')};
            background-color: ${p => p.theme.colors.gray('100')};
            position: relative;

            &:before {
                content: " ";
                height: 100%;
                width: 100%;
                position: fixed;
                position: absolute;
                display: block;
                left: -10vw;
                background-color: ${p => p.theme.colors.gray('100')};
                z-index: -1;
            }
        }
    `,

    Headline: styled.h2`
        ${mediaUp('tablet')} {
            padding-top: ${fluid(25, 65, 'tablet')};
            padding-bottom: ${fluid(25, 65, 'tablet')};
            font-size: ${p => p.theme.fontSizes.normal};
            text-transform: uppercase;
        }
    `,

    LocationsBtnWrapper: styled.div`
        ${mediaUp('tablet')} {
            display: flex;
            align-items: center;
            height: 100%;
        }
    `,

    LocationsBtn: styled.button`
        ${mediaUp('tablet')} {
            font-size: ${p => p.theme.fontSizes.large};
            color: ${p => p.theme.colors.light};
            border: ${rem(1)} solid currentColor;
            padding: ${fluid(10, 25, 'tablet')} ${fluid(15, 45, 'tablet')};
            margin-left: ${rem(10)};
            margin-right: ${rem(10)};
            text-transform: uppercase;
            background-color: ${p => p.theme.colors.primary};
            transition: all ${p => p.theme.transitions.normal} ease-in;

            &:hover {
                background-color: ${p => p.theme.colors.light};
                color: ${p => p.theme.colors.primary};
            }
        }
    `,

    SidebarWrapper: styled.div`
        ${mediaUp('tablet')} {
            pointer-events: all;
            position: absolute;
            bottom: 0;
            left: 0;
            background-color: ${p => p.theme.colors.primary};
            width: 100%;
            transform: translateX(-25%);
            overflow: hidden;
            visibility: hidden;
            opacity: 0;
            transition: all ${p => p.theme.transitions.normal} linear ${p => p.theme.transitions.normal};

            ${p => p.active && `
                visibility: visible;
                opacity: 1;
                transform: translateX(0);
                transition-delay: 0s;

                .sidebar {
                    opacity: 1;
                }
            `}
        }
    `,

    Sidebar: styled.div`
        ${mediaUp('tablet')} {
            opacity: 0;
            transition: opacity ${p => p.theme.transitions.normal} linear ${p => p.theme.transitions.normal};
            padding: ${fluid(50, 60, 'tablet')} ${fluid(25, 35, 'tablet')};
        }
    `,

    Sublist: styled.ul`
        ${mediaUp('tablet')} {
            display: grid;
            grid-auto-flow: column; 

            &:not(:last-child) {
                border-bottom: ${rem(1)} solid ${p => p.theme.colors.gray('600')};
            }
        }
    `,

    SidebarItem: styled.li`
        ${mediaUp('tablet')} {
            position: relative;
            text-align: center;

            &:not(:last-child):before {
                content: '';
                position: absolute;
                right: 0;
                top: 50%;
                height: ${rem(25)};
                width: ${rem(1)};
                background: ${p => p.theme.gradient()};
                transform: translateY(-50%);
            }

            &:first-child .location-link {
                padding-left: 0;
            }
            
            &:last-child .location-link {
                padding-right: 0;
            }
        }
    `,

    Location: styled.a`
        ${mediaUp('tablet')} {
            display: block;
            padding: ${fluid(15, 25, 'tablet')} ${fluid(15, 20, 'tablet')};
            color: ${p => p.theme.colors.light};
            font-size: ${p => p.theme.fontSizes.small};
            transition: opacity ${p => p.theme.transitions.normal} linear;

            &:hover {
                opacity: 0.6;
            }
        }
    `
});