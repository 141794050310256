import {useState} from 'react';

import wpApi from 'util/wp-api';

import s, {
	Field,
	FieldLabel,
	FormFields,
	FormInput,
	FormDropdown,
	FormTextArea,
	FormChoices,
	FormCheckbox,
	FormRadio,
	FormChoiceLabel,
	FormActions,
	SubmitText,
} from 'styles/components/form';

import Button from 'components/button';

export default function Form ({config, submitText, children, bgColor, styleName = 'dark'}) {
	const [formStatus, setFormStatus] = useState('ready');

	/**
	 * Handle the form submit.
	 *
	 * @param {object} e
	 */
	const handleSubmit = async e => {
		e.preventDefault();

		// Only allow 1 submission at a time.
		if (['sending', 'sent'].includes(formStatus)) return;
		setFormStatus('sending');

		const data = {};
		(new FormData(e.target)).forEach((v, n) => data[n] = v);

		const success = await wpApi.submitForm(1, data);
		setFormStatus(success ? 'sent' : 'error');

		if (!success) {
			setTimeout(() => setFormStatus('ready'), 5000);
		}
	};

	const Actions = () => {
		switch (formStatus) {
			case 'sending':
				return <s.FeedbackMessage color={styleName}>Sending your message...</s.FeedbackMessage>;

			case 'sent':
				return <s.FeedbackMessage type="success">We have received your message!</s.FeedbackMessage>;

			case 'error':
				return <s.FeedbackMessage type="error">There was an error sending your message.</s.FeedbackMessage>;

			default:
				return (
					<FormActions>
						{submitText && (
							<SubmitText color={styleName}>{submitText}</SubmitText>
						)}

						<Button styleName={styleName} type="submit" iconName="arrow-right">{config.button.text}</Button>
					</FormActions>
				);
		}
	};

	return config && (
		<s.FormWrapper $onSubmit={handleSubmit}>
			<form onSubmit={handleSubmit}>
				<FormFields>
					{config.fields.map((field, key) => {
						const fieldName = field.id;

						let Input;

						const attributes = {
							name: fieldName,
							required: field.isRequired,
							color: styleName
						};

						switch (field.type) {
							case 'textarea':
								Input = FormTextArea;
								attributes.rows = 4;
								break;

							case 'select':
								Input = FormDropdown;
								break;

							default:
								Input = FormInput;
								attributes.type = field.type;
								break;
						}

						const disabled = ['sending', 'sent'].includes(formStatus);

						return (
							<Field key={key} span={field.layoutGridColumnSpan} disabled={disabled}>
								<FieldLabel
									color={styleName}
									bgColor={bgColor}
									required={field.isRequired ? 1 : 0}>
										{field.label}
									</FieldLabel>

								{
									field.type === 'select' ? (
										<Input {...attributes} value="">
											{field.choices.map((option, optionKey) => (
												<option value={option.value} key={optionKey}>{option.text}</option>
											))}
										</Input>
									) : ['checkbox', 'radio'].includes(field.type) ? (
										<FormChoices>
											{field.choices.map((option, optionKey) => {
												let Choice = field.type === 'checkbox' ?
													FormCheckbox :
													FormRadio;

												return (
													<Choice value={option.value} key={optionKey}>
														<input type={field.type} name={fieldName} value={option.value}/>
														<span></span>
														<FormChoiceLabel>{option.text}</FormChoiceLabel>
													</Choice>
												);
											})}
										</FormChoices>
									) : (
										<Input {...attributes}/>
									)
								}
							</Field>
						);
					})}
				</FormFields>

				<Actions/>

				{children}
			</form>
		</s.FormWrapper>
	);
}
