import { useEffect, useState } from "react";
import s from "styles/components/header";
import useHeaderContext from "providers/header";
import useSiteOptions from "providers/useOptions";
import Menu from "./menu";
import Image from "next/image";
import MegaMenu from "./megamenu";
import MobileMenu from "./mobile-menu";
import Link from "next/link";
import { mediaQuery } from "util/media-query";

export default function Header({ isSticky = false }) {
  const { headerOffset, contentHasLoaded } = useHeaderContext();
  const { header } = useSiteOptions();

  const [position, setPosition] = useState(isSticky ? "absolute" : "relative");
  const [activeMegaMenu, setActiveMegaMenu] = useState();
  const [activeMobileMenu, setActiveMobileMenu] = useState();
  const [headerStyle, setHeaderStyle] = useState("dark");
  const [isVisible, setIsVisible] = useState(false);

  const locations = header?.locations;

  /**
   * IMPORTANT: only calls this one when MAIN tag is READY on the frontend
   * 1st: If is mobile, transform the default style to light
   * 2nd: Bail if there's no section
   * 3rd: Check if the scroll is higher than the 1st section height
   *  if so, add a dark style, assuming the next sections have a light style to make the logo/menu readable
   *  if not, add the light style, same reason.
   */
  useEffect(() => {
    const isMobile = window.innerWidth < mediaQuery["desktop-small"];
    if (isMobile) setHeaderStyle("light");

    const firstSection = document.querySelector("main > section");
    if (!firstSection) return;

    const { height } = firstSection.getBoundingClientRect();

    const onScroll = () => {
      if (window.scrollY > height) setHeaderStyle("dark");
      else setHeaderStyle("light");
    };

    window.addEventListener("scroll", onScroll);

    // Cleanup when the component gets unmounted
    return () => window.removeEventListener("scroll", onScroll);
  }, [contentHasLoaded]);

  /**
   * Everytime the isSticky changes,
   * checks its value and set the related positioning
   */
  useEffect(() => {
    setPosition(isSticky ? "absolute" : "relative");
  }, [isSticky]);

  /**
   * Add overflow hidden to the body in case the mobile menu is open
   */
  useEffect(() => {
    if (activeMobileMenu) {
      window.document.body.style.overflow = "hidden";

      return;
    }

    window.document.body.style.overflow = null;
  }, [activeMobileMenu]);

  /**
   * Will set the header position if is sticky
   * After the scroll offset is higher than offset
   * Add fixed position.
   */
  useEffect(() => {
    if (!isSticky) return;

    setIsVisible(true);

    const onScroll = () => {
      if (window.pageYOffset >= headerOffset) setPosition("fixed");
      else setPosition("absolute");
    };

    window.addEventListener("scroll", onScroll);

    // Cleanup when the component gets unmounted
    return () => window.removeEventListener("scroll", onScroll);
  }, [headerOffset, isSticky]);

  const hasMegaMenu = () => header?.items.some((item) => item.mega_menu.items);
  const isMegaMenuActive = (index) => activeMegaMenu === index;

  return (
    <>
      {isSticky && (
        <s.HeaderTop>
          <s.Wrapper size={"full"}>
            <s.Logo color={"light"}>
              <Image
                src={header?.logo.sizes.medium}
                alt={header?.logo.alt}
                width={170}
                height={50}
              />
            </s.Logo>
          </s.Wrapper>
        </s.HeaderTop>
      )}

      {header ? (
        <MobileMenu
          selectOptions={locations}
          data={header}
          active={activeMobileMenu}
          onItemClick={() => setActiveMobileMenu((state) => !state)}
        />
      ) : null}

      <s.Header
        visible={isVisible}
        color={headerStyle}
        position={position}
        offset={`${headerOffset}`}
        defaultStyling={!isSticky && !activeMobileMenu}
      >
        <s.Wrapper size={"full"}>
          <s.Logo>
            <Link href="/">
              <Image
                src={header?.logo.sizes.medium}
                alt={header?.logo.alt}
                width={170}
                height={50}
              />
            </Link>
          </s.Logo>
          <s.MobileMenu>
            {!isSticky && !activeMobileMenu && <s.MenuLabel>Menu</s.MenuLabel>}
            <s.MenuTrigger
              defaultStyling={!isSticky && !activeMobileMenu}
              active={activeMobileMenu}
              onClick={() => setActiveMobileMenu((state) => !state)}
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </s.MenuTrigger>
          </s.MobileMenu>
          <s.Nav>
            {header ? (
              <Menu items={header.items} onMenuHover={setActiveMegaMenu} />
            ) : null}
          </s.Nav>
        </s.Wrapper>
      </s.Header>
    </>
  );
}
