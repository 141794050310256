import styled from 'styled-components';
import fluid from 'util/fluid';
import rem from 'util/rem';
import AddressForm from 'components/address-form';

export default Object.freeze({
    Form: styled.div`
        position: relative;
        display: none;

        &.active {
            display: grid;
        }

        form {
            grid-template-columns: 1fr;

            button {
                margin-top: ${fluid(20, 50)};

                span {
                    &::after {
                        content: '';
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        width: 100%;
                        height: ${rem(2)};
                        background-image: ${p => p.theme.gradient()};
                      }
                }
            }

            .mapboxgl-ctrl-geocoder--input {
                border: 1px solid ${p => p.theme.colors.gray('300')};
                color: ${p => p.theme.colors.dark};
            }

            .suggestions-wrapper {
                position: relative;
            }

            label {
                background-color: #F8F7F7;
                color: ${p => p.theme.colors.primary};
                font-size: ${fluid(10, 18)};
            }
        }

    `,

    AddressForm: styled(AddressForm)`

    
    `,

    ButtonWrapper: styled.div`
        position: fixed;
        bottom: ${fluid(10, 25)};
        right: ${fluid(10, 25)};
        background: ${p => p.theme.colors.light};
        border-radius: 50%;
        padding: ${fluid(8,12)};
        z-index: 99;
        box-shadow: 0 -1px 10px 0px rgba(0,0,0,0.5);

        &.active {
            background: #F8F7F7;
            border-radius: 0;
            width: ${fluid(280, 550)};
            padding: ${fluid(15, 25)};

            .cancel-button {
                display: none;
            }
        }

        &.cancel {
            display: none;
        }
    `,

    Button: styled.button`
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${p => p.theme.colors.light};
        height: ${fluid(75, 150)};
        width: ${fluid(75, 150)};
        padding: ${rem(5)} ${rem(8)} 0;
        text-align: center;
        font-weight: 800;
        border-radius: 50%;
        font-size: ${fluid(12, 24)};
        background: linear-gradient(37.1deg, #4D00FF 2.79%, #BD30EB 26.8%, #FF61BB 54.25%, #FF8F65 74.7%, #FFC600 96.88%);

        &.hide {
            display:none;
        }
    `,

    Cancel: styled.div`
        position: absolute;
        top: 0;
        right: 0;
        z-index: 5;

        button {
            display: flex;
            align-items: center;
            color: currentColor;
            font-size: ${rem(18)};
            transition: color 300ms;

            i {
                position: relative;
                display: block;
                width: ${rem(20)};
                height: ${rem(20)};
                margin-left: ${rem(6)};

                &::before,
                &::after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: ${rem(18)};
                    height: ${rem(2)};
                    background: ${p => p.theme.colors.gray('300')};
                    margin: -1px 0 0 ${rem(-6)};
                }

                &::before {
                    transform: rotate(45deg);
                }

                &::after {
                    transform: rotate(-45deg);
                }
            }
        }
    `,

    CloseButton: styled.div`
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 5;

        button {
            display: flex;
            align-items: center;
            color: currentColor;
            font-size: ${rem(18)};
            transition: color 300ms;

            i {
                position: relative;
                display: block;
                width: ${rem(20)};
                height: ${rem(20)};
                margin-left: ${rem(6)};

                &::before,
                &::after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: ${rem(18)};
                    height: ${rem(2)};
                    background: ${p => p.theme.colors.gray('300')};
                    margin: -1px 0 0 ${rem(-6)};
                }

                &::before {
                    transform: rotate(45deg);
                }

                &::after {
                    transform: rotate(-45deg);
                }
            }
        }
    `
})