import ChevronDown from 'public/icons/chevron-down.svg';
import styled from 'styled-components';
import fluid from 'util/fluid';
import { mediaUp } from 'util/media-query';
import rem from 'util/rem';

export default Object.freeze({
	Link: styled.a`
        display: flex;
        align-items: center;
        position: relative;
        font-size: ${p => p.theme.fontSizes.normal};
		color: ${p => p.theme.colors.dark};
        transition: color ${p => p.theme.transitions.normal} ease;

        ${mediaUp('tablet')} {
            padding: ${rem(10)};
        }

        ${mediaUp('desktop-mlarge')} {
		    font-size: ${p => p.theme.fontSizes.large};
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            height: 100%;
            width: 0;
            margin: 0 50%;
            background-color: ${p => p.theme.colors.primary};
            transition: all ${p => p.theme.transitions.normal} ease 0s;
        }

        &:hover {
            color: ${p => p.theme.colors.light};

            &:before {
                width: 100%;
                margin: 0;
            }

            .icon {
                color: ${p => p.theme.colors.light};   
            }
        }

        span {
            position: relative;
            display: flex;
        }
	`,

	LinkIcon: styled(ChevronDown)`
		margin-left: ${fluid(5, 8)};
		width: ${rem(12)};
		color: ${p => p.theme.colors.primary};
	`,

    LinkWrapper: styled.div`
        &:hover {
            .dropdown {
                opacity: 1;
                visibility: visible;
                transition-delay: 0s;
            }

            .icon {

            }
        }
    `,

    DropdownWrapper: styled.div`
        ${mediaUp('tablet')} {
            background-color: ${p => p.theme.colors.light};
            position: absolute;
            opacity: 0;
            z-index: 1;
            visibility: hidden;
            transition: all ${p => p.theme.transitions.normal} ease;
            transition-delay: ${p => p.theme.transitions.normal};
            box-shadow: 0 ${rem(5)} ${rem(10)} rgba(0 0 0 / 10%);

            .nav-link {
                padding: ${fluid(10, 15, 'tablet')} ${fluid(15, 25, 'tablet')};
            }
        }
    `
});