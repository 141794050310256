import styled from 'styled-components';

import m from 'styles/components/modal';

import rem from 'util/rem';
import {mediaUp} from 'util/media-query';
import fluid from 'util/fluid';

const ModalInner = styled(m.ModalInner)`
  background: white;
  overflow-x: hidden;
  overflow-y: auto;

  ${mediaUp('desktop-small')} {
    display: grid;
    grid-template-columns: 8fr 4fr;
  }
  
  ${mediaUp('desktop-medium')} {
    grid-template-columns: 9fr 3fr;
  }
`;

const CloseButton = styled(m.CloseButton)`
  button {
	${mediaUp('desktop-small')} {
      color: white;
	}
	
    &:hover {
	  ${mediaUp('desktop-small')} {
        color: ${p => p.theme.colors.tertiary};
	  }
    }
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  gap: ${fluid(32, 60)};
`;

const LeftContent = styled(ContentWrapper)`
  background: white;
  padding: 100px ${fluid(20, 100)};
  overflow-y: auto;
  
  ${mediaUp('desktop-small')} {
    opacity: 0;
    transition: opacity;
    transition-duration: 300ms;
  }
  
  .open & {
	${mediaUp('desktop-small')} {
      opacity: 1;
      transition-delay: 250ms;
      transition-duration: 500ms;
	}
  }
`;

const RightContent = styled(ContentWrapper)`
  color: white;
  background: black;
  padding: ${rem(100)} ${fluid(20, 80)};
  
  ${mediaUp('desktop-small')} {
	padding-top: 0;
	padding-bottom: 0;
    transform: translateX(100%);
    transition: transform;
    transition-delay: 0s;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
  }
  
  .open & {
	${mediaUp('desktop-small')} {
      transform: translateX(0);
      transition-delay: 300ms;
      transition-duration: 500ms;
	}
  }
`;

const Headline = styled.h3`
  font-size: ${fluid(30, 72)};
  font-weight: bold;
  text-transform: uppercase;
`;

const FormWrapper = styled.div`
  width: 100%;
`;

const SmallText = styled.div`
  p {
    font-size: ${fluid(14, 18)};
    line-height: 1.4;

    &:not(:last-child) {
      margin-bottom: ${rem(18)};
    }
  }
`;

const ContactBlocks = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 35px;
  font-size: ${fluid(16, 20)};
`;

const ContactBlock = styled.div`
  padding-bottom: 33px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
`;

const ContactBlockTitle = styled.div`
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 12px;
`;

const ContactBlockText = styled.div`
  line-height: 1.4;

  a {
    color: white;
    transition: color 250ms;

    &:hover {
      color: ${p => p.theme.colors.secondary};
    }
  }
`;

const SocialLinks = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  
  ${mediaUp('tablet')} {
    justify-content: flex-end;
  }

  a {
    display: block;

    svg {
      display: block;
      fill: white;
      transition: fill 250ms;
    }
  }

  a:hover svg {
    fill: ${p => p.theme.colors.secondary};
  }
`;

export default {
	ModalInner,
	CloseButton,
	LeftContent,
	RightContent,
	Headline,
	FormWrapper,
	SmallText,
	ContactBlocks,
	ContactBlock,
	ContactBlockTitle,
	ContactBlockText,
	SocialLinks,
};