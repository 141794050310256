import Wrapper from 'components/wrapper';
import styled from 'styled-components';
import { plusIcon } from 'styles/mixins';
import fluid from 'util/fluid';
import { mediaUp } from 'util/media-query';
import rem from 'util/rem';

export default {
    Footer: styled.footer`
        position: relative;
        z-index: 1;
        background-color: ${p => p.theme.colors.light};
        padding: ${fluid(50, 120)} 0 ${fluid(50, 150)};
        margin-bottom: ${fluid(50, 70, 'phone-xsmall', 'desktop-small')};

        ${mediaUp('desktop-small')} {
            margin-bottom: 0;
        }
    `,

	Logo: styled.figure`
		width: ${fluid(100, 220)};
        margin-bottom: ${rem(35)};

        ${mediaUp('tablet')} {
            margin-bottom: 0;
        }
	`,

    Wrapper: styled(Wrapper)`
        position: relative;

        &:before {
            margin-bottom: ${fluid(20, 45, 'tablet')};
            display: block;
            height: ${rem(12)};
            border-top: ${rem(1)} solid ${p => p.theme.colors.gray('600')};
            border-left: ${rem(1)} solid ${p => p.theme.colors.gray('600')};
            
            ${mediaUp('tablet')} {
                content: '';
                grid-column-start: 1;
                grid-column-end: 3;
            }
        }
        
        ${mediaUp('tablet')} {
            display: grid;
            grid-template-columns: ${fluid(100, 220)} 1fr;
            column-gap: ${fluid(45, 95)};
        }
    `,

    Nav: styled.div`
        display: grid;
        row-gap: ${rem(25)};

        ${mediaUp('tablet')} {
            grid-auto-flow: column;
            column-gap: ${fluid(45, 95)};
        }

        .collapsible.active {
            em:after {
                transform: rotate(90deg) translateX(-50%);
                opacity: 0;
            }
        }
    `,

    CollapsibleHeader: styled.header`
        display: grid;
        grid-template-columns: 1fr max-content;
        
        &:after {
            content: '';
            margin: ${rem(10)} 0;
            display: block;
            height: ${rem(12)};
            border-top: ${rem(1)} solid ${p => p.theme.colors.gray('100')};
            border-left: ${rem(1)} solid ${p => p.theme.colors.gray('100')};
            grid-column-start: 1;
            grid-column-end: 3;
            
            ${mediaUp('tablet')} {
                content: none;
            }
        }
    `,

    CollapsibleTitle: styled.p`
        display: block;
        color: ${prop => prop.theme.colors.dark};
        font-size: ${prop => prop.theme.fontSizes.xlarge};

        ${mediaUp('tablet')} {
            margin-bottom: ${fluid(25, 50)};
        }
    `,

    Icon: styled.em`
        ${mediaUp('tablet')} {
            display: none;
        }

        ${plusIcon(rem(17))};
    `,

    SubLinks: styled.div`
        display: flex;
        flex-direction: column;
        padding-left: ${rem(10)};

        ${mediaUp('tablet')} {
            padding-left: 0;
        }
    `,
    
    SubLink: styled.a`  
        color: ${prop => prop.theme.colors.gray('600')};
        font-size: ${prop => prop.theme.fontSizes.small};
        line-height: 1.27;
        margin-bottom: ${rem(8)};
    `,

    SocialMedia: styled.div`
        display: flex;
        justify-content: center;
        margin-top: ${fluid(35, 150)};
    `,

    Link: styled.a`
        margin: 0 ${rem(20)};
    `,
    
    MediaLink: styled.span`
        display: block;
        height: ${fluid(15, 20)};
        color: ${prop => prop.theme.colors.dark};

        &,
        svg {
            transition: all ${prop => prop.theme.transitions.normal} ease;
        }

        svg {
            width: 100%;
            height: 100%;
        }

        &:not(:last-child) {
            margin-right: ${fluid(25, 40)};
        }

        &:hover {
            color: ${prop => prop.theme.colors.primary};
            
            svg {
                transform: translateY(${rem(-5)});
            }
        }
    `,
};