import s from 'styles/components/mobile-menu';
import Button from './button';
import Link from 'next/link';
import {useEffect, useState} from 'react';
import Router from 'next/router';
import useModals from 'providers/useModals';

export default function MobileMenu ({data, active, onItemClick, selectOptions}) {
	const modals = useModals();

	const {items, actions} = data;
	const [currentLocation, setCurrentLocation] = useState();

	useEffect(() => {
		if (!currentLocation) return () => {};

		if (!currentLocation.target) {
			Router.push(currentLocation.url);
		}

		if (currentLocation.target === '_blank' && window) {
			window.open(currentLocation.url, '_blank');
		}

		onItemClick();
	}, [currentLocation]);

	const groupHeader = item => (<s.CollapsibleHeader>
		<s.Icon active={item.enable_sub_menu}/>
		<s.CollapsibleTitle>{item.link.title}</s.CollapsibleTitle>
	</s.CollapsibleHeader>);

	const groupItem = item => {
		const type = item.sub_menu_type;
		let submenu = type === 'dropdown' ? item.items : item.mega_menu.items;

		if (type === 'megamenu')
			submenu = submenu.map(subitem => {
				return {
					title: subitem.link.title,
					url: subitem.link.url,
					target: subitem.link.target,
				};
			});

		return submenu ? <s.SubLinks>
			{submenu.map((subitem, key) => (
				<Link key={key} href={subitem.url || '#'} target={subitem.target} passHref>
					<s.SubLink onClick={onItemClick}>{subitem.title}</s.SubLink>
				</Link>))}
		</s.SubLinks> : null;
	};

	return <s.Content active={active}>
		<s.Nav>
			{
				items.length && items.map((item, index) => item.enable_sub_menu ?
					(
						<s.Collapsible key={index} title={groupHeader(item)}>
							{groupItem(item)}
						</s.Collapsible>
					) : (
						<s.CollapsibleHeader key={index}>
							<s.Spacer/>
							<Link key={item.link.title} href={item.link.url || '#'} target={item.link.target} passHref>
								<s.Link onClick={onItemClick}>{item.link.title}</s.Link>
							</Link>
						</s.CollapsibleHeader>
					),
				)
			}
		</s.Nav>
	</s.Content>;
}