import s from 'styles/components/footer';
import Link from 'next/link';
import Collapsible from './collapsible';
import useSiteOptions from 'providers/useOptions';
import Image from 'next/image';
import socialLinkIcons from 'util/social-link-icons';

export default function Footer () {
	const {footer, social_media} = useSiteOptions();

	const getSocialMediaIcon = name => {
		const Icon = socialLinkIcons[name];

		if (!Icon) return <></>;

		return <Icon/>;
	};

	const groupHeader = item => (<s.CollapsibleHeader>
		<s.CollapsibleTitle>{item.link.title}</s.CollapsibleTitle>
		<s.Icon/>
	</s.CollapsibleHeader>);

	const groupItem = item => {
		return item.enable_sub_menu && <s.SubLinks>
			{item.items.map(subitem => (
				<Link
					key={subitem.title}
					href={subitem.url || '#'}
					target={subitem.target}
					passHref>
					<s.SubLink>
						<span dangerouslySetInnerHTML={{__html: subitem.title}}></span>
					</s.SubLink>
				</Link>))}
		</s.SubLinks>;
	};

	return <s.Footer>
		<s.Wrapper size={'full'}>
			<s.Logo>
				<Image src={footer.logo.sizes.medium} alt={footer.logo.alt} objectFit="contain" width={220} height={60}/>
			</s.Logo>
			<s.Nav>
				{(footer && footer.items.length) ? footer.items.map(item => (
					<Collapsible key={item.link.title} mobileOnly={true} title={groupHeader(item)}>
						{groupItem(item)}
					</Collapsible>
				)) : null}
			</s.Nav>
		</s.Wrapper>

		{social_media.length && (
			<s.SocialMedia>
				{social_media.map(media => (
					<s.Link key={media.icon.value} href={media.url || '#'} target="_blank" rel="noopener noreferrer">
						<s.MediaLink>{getSocialMediaIcon(media.icon.value)}</s.MediaLink>
					</s.Link>
				))}
			</s.SocialMedia>
		)}
	</s.Footer>;
}
