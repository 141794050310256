import {motion} from 'framer-motion';
import Header from 'components/header';
import Footer from 'components/footer';
import {useRouter} from 'next/router';
import {useState, useEffect} from 'react';

const Layout = ({children}) => {
	const [isSticky, setIsSticky] = useState(false);
	const router = useRouter();
	const HOME_PATH = '/';

	useEffect(() => {
		setIsSticky(router.route === HOME_PATH);
	}, [router]);

	return <motion.div
		initial={{opacity: 0}}
		animate={{opacity: 1}}
		exit={{opacity: 0}}
	>
		<Header isSticky={isSticky}/>
		{children}
		<Footer/>
	</motion.div>;
};
export default Layout;